<template>
	<div :class="[{ 'show-overlay': $store.state.app.shallShowOverlay }, $route.meta.contentClass]" class="app-content content">
		<div class="content-overlay" />
		<div class="header-navbar-shadow" />
		<div :class="contentWidth === 'boxed' ? 'container p-0' : null" class="content-wrapper">
			<!--      <slot name="breadcrumb">-->
			<!--        <app-breadcrumb />-->
			<!--      </slot>-->
			<div class="content-body">
				<transition :name="routerTransition" mode="out-in">
					<slot />
				</transition>
			</div>
		</div>
	</div>
</template>

<script>
	import AppBreadcrumb from "@core/layouts/components/AppBreadcrumb.vue";
	import useAppConfig from "@core/app-config/useAppConfig";
	import { routerParams } from "@/libs/utils/routerParams";
	import { computed, reactive, toRefs } from "@vue/composition-api";

	export default {
		components: {
			AppBreadcrumb,
		},
		setup() {
			const { routerTransition, contentWidth } = useAppConfig();
			const event = reactive({
				project_id: computed(() => {
					return routerParams("project_id");
				}),
			});
			return {
				routerTransition,
				contentWidth,
				...toRefs(event),
			};
		},
	};
</script>

<style></style>
