<template>
    <div
        :class="[
            {
                expanded:
                    !isVerticalMenuCollapsed ||
                    (isVerticalMenuCollapsed && isMouseHovered),
            },
            skin === 'semi-dark' ? 'menu-dark' : 'menu-light',
        ]"
        class="main-menu menu-fixed menu-accordion menu-shadow"
        @mouseenter="updateMouseHovered(true)"
        @mouseleave="updateMouseHovered(false)"
    >
        <!-- main menu header-->
        <div class="navbar-header expanded">
            <slot
                :collapseTogglerIcon="collapseTogglerIcon"
                :toggleCollapsed="toggleCollapsed"
                :toggleVerticalMenuActive="toggleVerticalMenuActive"
                name="header"
            >
                <ul class="nav navbar-nav flex-row">
                    <!-- Logo & Text -->
                    <li class="nav-item mr-auto">
                        <b-link class="navbar-brand" to="/">
                            <span class="brand-logo">
                                <!-- <b-img :src="appLogoImage" alt="logo" /> -->
                                <b-icon-stars scale="1.5" class="ml-75" />
                            </span>
                            <h2 class="brand-text">
                                {{ appName }}
                            </h2>
                        </b-link>
                    </li>
                    <!-- <b-button
            :style="{ background: 'transparent' }"
            style="width: calc(100% - 40px);height:50px"
            to="/project-list"
            variant="outline-primary"
			class="mr-50"
          >
            <feather-icon class="mr-50" icon="MapIcon" />
            <span class="align-middle">项目地图</span>
          </b-button> -->

                    <!-- Toggler Button -->
                    <li class="nav-item nav-toggle">
                        <b-link class="nav-link modern-nav-toggle">
                            <feather-icon
                                class="d-block d-xl-none"
                                icon="XIcon"
                                size="20"
                                @click="toggleVerticalMenuActive"
                            />
                            <feather-icon
                                :icon="collapseTogglerIconFeather"
                                class="d-none d-xl-block collapse-toggle-icon"
                                size="20"
                                @click="toggleCollapsed"
                            />
                        </b-link>
                    </li>
                </ul>
            </slot>
        </div>
        <!-- / main menu header-->

        <!-- Shadow -->
        <div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" />

        <!-- <template v-if="isNavMenuHidden"> -->
        <!-- <ul
      :style="'border-right:1px solid ' + (isDark ? '#d0d2d6' : '#6e6b7b')"
      class="nav navbar-nav pr-1 mr-1"
      style="border-radius: 0; border: 2px solid red"
    >
      <li class="nav-item">
        <b-link
          v-show="show_project_list"
          v-b-toggle.project-list
          class="nav-link"
        >
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
        <b-link
          v-show="!show_project_list"
          v-b-toggle.app-sidebar-menu
          class="nav-link"
        >
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul> -->
        <!-- </template> -->

        <!-- main menu content-->
        <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="main-menu-content scroll-area"
            tagname="ul"
            @ps-scroll-y="
                (evt) => {
                    shallShadowBottom = evt.srcElement.scrollTop > 0;
                }
            "
        >
            <!-- <vertical-nav-menu-items
        :items="$store.state.menus.tree"
        class="navigation navigation-main"
      /> -->
            <vertical-nav-menu-items
                :items="menuTree"
                class="navigation navigation-main"
            />
            {{ $store.state.menu_tree }}
        </vue-perfect-scrollbar>

        <!-- /main menu content-->
    </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { BImg, BLink } from "bootstrap-vue";
import {
    computed,
    onMounted,
    provide,
    ref,
    reactive,
    toRefs,
} from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import { $themeConfig } from "@themeConfig";
import VerticalNavMenuItems from "./components/vertical-nav-menu-items/VerticalNavMenuItems.vue";
import useVerticalNavMenu from "./useVerticalNavMenu";
import store from "@/store";
import { routerParams } from "@/libs/utils/routerParams";

export default {
    components: {
        VuePerfectScrollbar,
        VerticalNavMenuItems,
        BLink,
        BImg,
    },
    props: {
        isVerticalMenuActive: {
            type: Boolean,
            required: true,
        },
        toggleVerticalMenuActive: {
            type: Function,
            required: true,
        },
    },
    setup(props) {
        const {
            isMouseHovered,
            isVerticalMenuCollapsed,
            collapseTogglerIcon,
            toggleCollapsed,
            updateMouseHovered,
        } = useVerticalNavMenu(props);

        const { skin } = useAppConfig();

        // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
        const shallShadowBottom = ref(false);

        provide("isMouseHovered", isMouseHovered);

        const perfectScrollbarSettings = {
            maxScrollbarLength: 60,
            wheelPropagation: false,
        };

        const collapseTogglerIconFeather = computed(() =>
            collapseTogglerIcon.value === "unpinned" ? "CircleIcon" : "DiscIcon"
        );

        // App Name
        const { appName, appLogoImage } = $themeConfig.app;

        const event = reactive({
            menuTree: computed(() => {
                return menuFormat(store.state.menus.tree);
            }),
        });

        const menuFormat = (menus_tree = []) => {
            let menus = [];
            if (menus_tree.length > 0) {
                menus = [
                    // {
                    //   title: "总览",
                    //   icon: "",
                    //   menu_id: -99,
                    //   p_menu_id: 0,
                    //   children: menus_tree,
                    // },
                    {
                        header: "项目导航",
                    },
                    ...menus_tree,
                    //管理页面
                    {
                        header: "System Manage",
                        menu_id: -1,
                    },
                    {
                        title: "System Settings",
                        icon: "SettingsIcon",
                        menu_id: -1,
                        p_menu_id: 0,
                        children: [
                            {
                                title: "Users Manage",
                                route: {
                                    name: "admin-users-list",
                                    params: {
                                        title: "Users Manage",
                                        icon: "UserIcon",
                                        levels: [
                                            {
                                                title: "System Manage",
                                            },
                                        ],
                                    },
                                },
                            },
                            {
                                title: "Projects Manage",
                                route: {
                                    name: "admin-projects-list",
                                    params: {
                                        title: "Projects Manage",
                                        icon: "GridIcon",
                                        levels: [
                                            {
                                                title: "System Manage",
                                            },
                                        ],
                                    },
                                },
                            },
                            {
                                title: "Menus Manage",
                                route: {
                                    name: "admin-menus-list",
                                    params: {
                                        title: "Menus Manage",
                                        icon: "MenuIcon",
                                        levels: [
                                            {
                                                title: "System Manage",
                                            },
                                        ],
                                    },
                                },
                            },
                        ],
                    },
                ];
                // 四川大厦项目嵌入日历模式外链路由
                const project_id = routerParams("project_id");
                // console.log('project_id',project_id);
                if(project_id == 82){
                    let index = menus.findIndex(m => m.menu_id===-1 && m.title === "System Settings")
                    menus[index].children.push({
                        title: "日历模式",
                        route: {
                            name: "index-project-iframe",
                            params: {
                                title: "日历模式",
                                icon: "MenuIcon",
                                levels: [
                                    {
                                        title: "System Manage",
                                    },
                                ],
                                src:"aHR0cDovL3A0Lmlud2hpbGUuY29tOjc2MjIvY2FsZW5kYXI/dXNlck5hbWU9Z2praiZ1c2VyUHdkPWdqa2oxMjM0"
                            },
                        },
                    })
                }
            }
            
            return [
                //前端页面
                // {
                //     title: "Project List",
                //     icon: "MapIcon",
                //     route: "index-project-list",
                // },
                ...menus,
                {
                    title: "Account Settings",
                    icon: "UserIcon",
                    menu_id: -1,
                    p_menu_id: 0,
                    route: {
                        name: "admin-account-setting",
                        icon: "UserIcon",
                        params: {
                            title: "Account Settings",
                        },
                    },
                },
                /* ...vuexy_demo_navigation,  */
            ];
        };

        onMounted(() => {
            console.log("菜单导航========", props.menuTree);
        });

        return {
            perfectScrollbarSettings,
            isVerticalMenuCollapsed,
            collapseTogglerIcon,
            toggleCollapsed,
            isMouseHovered,
            updateMouseHovered,
            collapseTogglerIconFeather,

            // Shadow Bottom
            shallShadowBottom,

            // Skin
            skin,

            // App Name
            appName,
            appLogoImage,
            ...toRefs(event),
        };
    },
};
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
